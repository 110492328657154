import React from 'react';

const Member = ({ member, small }) => {

    const memberInfo = (
        <React.Fragment>
            <img src={member.img} alt={member.name} />
            {member.name && <h3>{member.name}</h3>}
            {member.description && <p>{member.description}</p>}
        </React.Fragment>
    );

    const className = small ? "member-small" : "member";

    // If a link is provided, render an anchor tag
    if (member.link) {
        return (
            <div className={className}>
                <a href={member.link} target="_blank" rel="noreferrer" >
                    {memberInfo}
                </a>
            </div>
        );
    }
    return (
    <div className={className}>
        {memberInfo}
    </div>
    );
};

export default Member;
