import React from 'react';

const Contact = () => {
  return (
    <div>
      <h2>Contact</h2>
      <p>You can follow us on <a href="https://www.linkedin.com/company/scale-angels/">LinkedIn</a>.</p>
    </div>
  );
};

export default Contact;
