import React from 'react';
import ScaleAngelsLogo from '../assets/scale_angels_logo.png';

const Overview = () => {
  return (
    <div className="flexDiv" style={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    }}>
      <div style={{
        flex: '1 1 300px',
        display: 'flex',
        justifyContent: 'center',
        order: 1,
      }}>
        <img 
          src={ScaleAngelsLogo} 
          alt="Scale Angels Logo" 
          style={{
            height: 'auto',
            maxHeight: '500px',
            maxWidth: '100%',
            padding: '10px'
          }} 
        />
      </div>
      <div style={{
        flex: '1 1 300px',
        order: 2,
      }}>
        <h2>About Us</h2>
        <p>We are a micro fund comprised of Scale AI employees and alumni, writing $25k and $50k checks into seed rounds of AI companies either started by Scale Alumni or within our broader network of founders. Our partners are spread across every major AI startup, providing access to a vast network of AI experts.</p>
        <p><i>We are not officially affiliated with Scale AI.</i></p>
      </div>
    </div>
  );
};

export default Overview;
