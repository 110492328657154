import React, { useState, useEffect } from 'react';
import './App.css';
import Overview from './components/Overview';
import MemberList from './components/MemberList';
import Contact from './components/Contact';
// import anon_profile from './assets/anon_profile.jpeg';
import anthropic from './assets/partners/anthropic.jpeg';
import character from './assets/partners/character.jpeg';
import cognition from './assets/partners/cognition.jpeg';
import openai from './assets/partners/openai.jpeg';
import perplexity from './assets/partners/perplexity.jpeg';
import recallCapital from './assets/partners/recall_capital.jpeg';
import scale from './assets/partners/scale.jpeg';
import xai from './assets/partners/xai.jpeg';
import sierra from './assets/partners/sierra.jpeg';
import decagon from './assets/partners/decagon.jpeg';
import cartesia from './assets/portfolio/cartesia.png';
import pantheon from './assets/portfolio/pantheon.png';
import retailready from './assets/portfolio/retailready.jpeg';
import toma from './assets/portfolio/toma.jpeg';
import worldexLab from './assets/portfolio/worldex_lab.jpeg';
import avoca from './assets/portfolio/avoca.jpeg';
import central from './assets/portfolio/central.jpeg';
import simpleAI from './assets/portfolio/simple_ai.jpeg';
import numiro from './assets/portfolio/numiro.jpeg';
import stealth from './assets/portfolio/stealth_company.jpeg';

// const partners = [
//   { id: 1, name: 'Timothy "Chongz" Luong', description: "Employee at SomeCompany", img: anon_profile },
//   { id: 2, name: "Arno Gao", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 3, name: "Sarah Niyogi", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 4, name: "Albert Sheu", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 5, name: "Barrett Woodside", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 6, name: "Eric Lee", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 7, name: "Jonah Bolotin", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 8, name: "Pawan Deshpande", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 9, name: "Calvin Huang", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 10, name: "Fernando Amat Gil", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 11, name: "Alan Yu", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 12, name: "Andrew Liu", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 13, name: "Argyris Zymnis", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 14, name: "Ashraf Alhashim", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 15, name: "Bihan Jiang", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 16, name: "Catherine Ma", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 17, name: "David Zhu", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 18, name: "Drake Wong", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 19, name: "Emily Wen", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 20, name: "Grace Hua", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 21, name: "Matthew Kaufer", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 22, name: "Michael Chen", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 23, name: "Shah Xia", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 24, name: "Shannon Hong", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 25, name: "Sophia Luo", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 26, name: "Tiger Sun", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 27, name: "Tony Zhang", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 28, name: "Will Sheu", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 29, name: "Yi Xu", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 30, name: "Yunfeng Bai", description: "Employee at SomeCompany", img: anon_profile },
//   { id: 31, name: "Yuting Duan", description: "Employee at SomeCompany", img: anon_profile },
//   // Add more members as needed
// ];

const portfolioCompanies = [
  { id: 'decagon', name: 'Decagon', description: 'Enterprise-grade generative AI for customer support', img: decagon, link: 'https://decagon.ai'},
  { id: 'cartesia', name: 'Cartesia AI', description: 'Training next-gen foundation models with subquadratic deep learning architectures', img: cartesia, link: 'https://cartesia.ai'},
  { id: 'pantheon', name: 'Pantheon AI', description: 'Automating the architecture of buildings', img: pantheon, link: 'https://www.withpantheon.ai'},
  { id: 'avoca', name: 'Avoca AI', description: 'The AI Platform for HVAC, Plumbing and Electrical Businesses', img: avoca, link: 'https://avoca.ai'},
  { id: 'central', name: 'Central AI', description: 'Payroll, benefits and government bullsh*t for startups', img: central, link: 'https://centralhq.com'},
  { id: 'simpleAI', name: 'Simple AI', description: 'A 10x better Siri', img: simpleAI, link: 'https://www.usesimple.ai/'},
  { id: 'retailready', name: 'RetailReady', description: 'A unified approach to retail compliance', img: retailready, link: 'https://retailreadyai.com'},
  { id: 'toma', name: 'Toma AI', description: 'AI phone automation for auto dealerships', img: toma, link: 'https://www.toma.so'},
  { id: 'numiro', name: 'Numiro', description: 'AI-powered tax prep', img: numiro, link: 'https://numiro.ai'},
  { id: 'worldexLab', name: 'Worldex Lab', description: 'Building the new internet for kids to connect to the physical world', img: worldexLab, link: 'https://worldexlab.com'},
  { id: 'stealth1', name: 'Stealth Company', img: stealth},
]

const partnerCompanies = [
  { id: 'scaleAI', name: 'Scale AI', img: scale, link: 'https://scale.com'},
  { id: 'openAI', name: 'OpenAI', img: openai, link: 'https://openai.com'},
  { id: 'anthropic', name: 'Anthropic', img: anthropic, link: 'https://anthropic.com'},
  { id: 'xai', name: 'XAI', img: xai, link: 'https://x.ai'},
  { id: 'perplexity', name: 'Perplexity', img: perplexity, link: 'https://perplexity.ai'},
  { id: 'sierra', name: 'Sierra', img: sierra, link: 'https://sierra.ai'},
  { id: 'cognition', name: 'Cognition', img: cognition, link: 'https://cognition.ai'},
  { id: 'decagon', name: 'Decagon', img: decagon, link: 'https://decagon.ai'},
  { id: 'character', name: 'Character', img: character, link: 'https://character.ai'},
  { id: 'cartesia', name: 'Cartesia AI', img: cartesia, link: 'https://cartesia.ai'},
  { id: 'recallCapital', name: 'Recall Capital', img: recallCapital, link: 'https://www.recall.capital/'},
]

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const NavLinks = () => (
    <>
      <a href="#overview">Overview</a>
      <a href="#portfolio">Portfolio</a>
      <a href="#contact">Contact</a>
    </>
  );

  return (
    <div className="App">
      <header className="App-header" style={isMobile ? {paddingLeft: '0'} : {}}>
        <h1 style={isMobile ? {textAlign: 'center' } : {}}>Scale Angels Fund</h1>
        <nav>
          {isMobile ? (
            <div className="mobile-menu">
              <div className="dropdown-menu">
                <NavLinks />
              </div>
            </div>
          ) : (
            <NavLinks />
          )}
        </nav>
      </header>
      <main>
        <section id="overview">
          <Overview />
        </section>
        <section id="portfolio">
          <MemberList title="Portfolio" membersData={portfolioCompanies}/>
        </section>
        {/* <section id="partners">
          <MemberList title="Partners" membersData={partners} />
        </section> */}
        <section id="backedBy">
          <MemberList title="Backed By Partners From" membersData={partnerCompanies} small/>
        </section>
        <section id="contact">
          <Contact />
        </section>
      </main>
    </div>
  );
}

export default App;
