import React from 'react';
import Member from './Member';

const MemberList = ({ title, membersData, small }) => {
  return (
    <div>
      <h2>{title}</h2>
      <div className="member-grid">
        {membersData.map(member => <Member key={member.id} member={member} small={small} />)}
      </div>
    </div>
  );
};

export default MemberList;
